<template>
  <div>
    <b-card
      v-if="userData"
    >
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.firstname }} {{ userData.lastname }}
                </h4>
                <span class="card-text">{{ userData.email }}</span>
              </div>
              <div
                v-if="$store.state.auth.role === 1"
                class="d-flex flex-wrap"
              >
                <b-button
                  :to="{ name: 'users-update', params: { id: userData.id } }"
                  variant="primary"
                >
                  Modifier
                </b-button>
                <b-button
                  class="ml-1"
                  variant="outline-primary"
                  @click="deleteUser()"
                >
                  Supprimer
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Pseudo</span>
              </th>
              <td class="pb-50">
                {{ userData.username }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BriefcaseIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Entreprise</span>
              </th>
              <td class="pb-50">
                {{ userData.enterprise }}
              </td>
            </tr>
            <tr
              v-if="$store.state.auth.role === 1"
            >
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Channel</span>
              </th>
              <td
                v-if="channel"
              >
                <div
                  v-if="typeof channel === 'object'"
                  class="d-flex"
                >
                  <div
                    v-for="chan, indexChan in channel"
                    :key="indexChan"
                  >
                    <b-badge
                      :variant="`light-${resolveChannelVariant(chan)}`"
                      pill
                    >
                      {{ chan }}
                    </b-badge>
                  </div>
                </div>
                <div v-else>
                  <b-badge
                    :variant="`light-${resolveChannelVariant(channel)}`"
                    pill
                  >
                    {{ channel }}
                  </b-badge>
                </div>
              </td>
            </tr>
            <tr
              v-if="$store.state.auth.role === 1"
            >
              <th class="pb-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Scopes</span>
              </th>
              <td>
                <div
                  v-if="userData.scopes.includes('superadmin')"
                >
                  <b-avatar
                    v-b-tooltip.hover
                    title="Superadmin"
                    :variant="`light-${resolveScopeVariant('superadmin')}`"
                  >
                    S
                  </b-avatar>
                </div>
                <div
                  v-if="!userData.scopes.includes('superadmin') && userData.scopes.includes('admin')"
                >
                  <b-avatar
                    v-b-tooltip.hover
                    title="Admin"
                    :variant="`light-${resolveScopeVariant('admin')}`"
                  >
                    A
                  </b-avatar>
                </div>
                <div
                  v-if="!userData.scopes.includes('superadmin') && !userData.scopes.includes('admin') && userData.scopes.includes('user')"
                >
                  <b-avatar
                    v-b-tooltip.hover
                    title="User"
                    :variant="`light-${resolveScopeVariant('user')}`"
                  >
                    U
                  </b-avatar>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>
                {{ userData.phoneNumber }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="devicesListFromUser && devicesListFromUser.length > 0"
      no-body
    >
      <b-card-header>
        <h5>Devices</h5>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          @click="deploy('device')"
        >
          <feather-icon
            :icon="deployDevice ? 'ChevronDownIcon' : 'ChevronUpIcon'"
            size="20"
          />
        </b-button>
      </b-card-header>
      <b-table
        v-if="devicesListFromUser.length > 0"
        class="position-relative"
        :items="devicesListFromUser.slice(
          (currentPage - 1) * perPage,
          currentPage * perPage,
        )"
        responsive
        :fields="tableColumnsDevices"
        primary-key="id"
        show-empty
        empty-text="Aucun device trouvé"
        :sort-desc="true"
        :style=" deployDevice ? 'display: none;' : ''"
      >
        <template #cell(name)="data">
          <b-link
            class="font-weight-bold d-block link-hover"
            :to="{ name: 'devices-view', params: { id: data.item.id} }"
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <template #cell(enterprise)="data">
          <div>
            <small>
              {{ data.item.enterprise }}
            </small>
          </div>
        </template>

        <template #cell(deviceType_id)="data">
          <small>
            {{ data.item.deviceType.name }}
          </small>
        </template>

        <template #cell(headAssociate)="data">
          <small>
            {{ data.item.headAssociate }}
          </small>
        </template>

        <template #cell(lugnium)="data">
          <b-avatar
            :variant="`light-${resolveActiveVariant(data.item.events.some(event => event.title === 'Lugnium' && (Date.parse(event.endDate) > Date.now())))}`"
          >
            <feather-icon
              :icon="data.item.events.some(event => event.title === 'Lugnium' && (Date.parse(event.endDate) > Date.now())) ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </template>

        <template #cell(cofrac)="data">
          <b-avatar
            :variant="`light-${resolveActiveVariant(data.item.cofrac)}`"
          >
            <feather-icon
              :icon="data.item.cofrac ? 'AwardIcon' : 'XIcon'"
            />
          </b-avatar>
        </template>

        <template #cell(is_active)="data">
          <b-avatar
            :variant="`light-${resolveActiveVariant(data.item.is_active)}`"
          >
            <feather-icon
              :icon="data.item.is_active ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </template>
      </b-table>
    </b-card>
    <b-card
      v-if="sitesList && sitesList.length > 0"
      no-body
    >
      <b-card-header>
        <h5>Sites</h5>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          @click="deploy('site')"
        >
          <feather-icon
            :icon="deploySite ? 'ChevronDownIcon' : 'ChevronUpIcon'"
            size="20"
          />
        </b-button>
      </b-card-header>
      <b-table
        v-if="sitesList.length > 0"
        class="position-relative"
        :items="sitesList.slice(
          (currentPage - 1) * perPage,
          currentPage * perPage,
        )"
        responsive
        :fields="tableColumnsSites"
        primary-key="id"
        show-empty
        empty-text="Aucun site trouvé"
        :sort-desc="true"
        :style=" deploySite ? 'display: none;' : ''"
      >
        <template #cell(updatedAt)="data">
          <b-link
            class="font-weight-bold d-block link-hover"
          >
            {{ data.item.mission }}
          </b-link>
          <small>{{ data.item.updatedAt | displayDate }}</small>
        </template>

        <template #cell(client)="data">
          <div>
            <small>
              {{ data.item.client }}
            </small>
          </div>
        </template>

        <template #cell(site)="data">
          <div>
            <small>
              {{ data.item.site }}
            </small>
          </div>
        </template>

        <template #cell(method)="data">
          <div>
            <small>
              {{ data.item.method }}
            </small>
          </div>
        </template>

        <template #cell(id)="data">
          <div>
            <b-link
              :href="`https://online.normenjeu.com/sites/${data.item.id}`"
              target="_blank"
            >
              go to online
            </b-link>
          </div>
        </template>

        <template #cell(device_id)="data">
          <div
            v-if="data.item.device_id"
          >
            <small>
              {{ data.item.device_id.name }}
            </small>
          </div>
        </template>
      </b-table>
      <div
        class="mx-2 mb-2 d-flex align-items-end justify-content-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="sitesList.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          :style=" deploySite ? 'display: none;' : ''"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BBadge,
  BLink, BCardHeader, BPagination, BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BCardHeader,
    BPagination,
    BAvatar,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      userData: null,
      channel: null,
      tableColumnsSites: [
        { key: 'updatedAt', label: 'Mission' },
        { key: 'client', label: 'Client' },
        { key: 'site', label: 'Site' },
        { key: 'method', label: 'Méthode' },
        { key: 'id', label: 'Online' },
        { key: 'device_id', label: 'Device' },
      ],
      tableColumnsDevices: [
        { key: 'name', label: 'Name', sortable: false },
        { key: 'enterprise', label: 'Enterprise', sortable: false },
        {
          key: 'deviceType_id', label: 'Type', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'headAssociate', label: 'Tête associée', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'lugnium', label: 'Lugnium', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'cofrac', label: 'Cofrac', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'is_active', label: 'Actif', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
      ],
      deploySite: true,
      deployDevice: true,
      sitesList: null,
      // pagination
      perPage: 10,
      currentPage: 1,
      allDevices: store.state.device.devicesList,
      // devicesList
      devicesListFromUser: null,
    }
  },
  mounted() {
    this.getUser()
    this.getSites()
  },
  methods: {
    getUser() {
      store.dispatch('user/findUser', this.$router.currentRoute.params.id)
        .then(response => {
          this.userData = response
          this.channel = JSON.parse(atob(response.preferences)).channel
          store.dispatch('device/findDevicesByUser', response.id)
            .then(res => {
              this.devicesListFromUser = res
            })
        })
        .catch(err => {
          console.log('err', err)
          this.userData = null
        })
    },
    deleteUser() {
      this.$swal({
        title: 'Êtes-vous sûr de vouloir supprimer ?',
        text: 'Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('user/deleteUser', this.$router.currentRoute.params.id)
          this.$swal({
            icon: 'success',
            title: 'Supprimé!',
            text: 'L\'utilisateur a bien été supprimé',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$router.push({ name: 'users' })
        }
      })
    },
    getSites() {
      store.dispatch('device/findSitesByUser', this.$router.currentRoute.params.id)
        .then(response => {
          this.sitesList = response.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
          if (response.length > 0) {
            this.sitesList.map(site => {
              const [devices] = this.allDevices.filter(device => device.id === site.device_id)
              // eslint-disable-next-line no-param-reassign
              site.device_id = devices
              return site
            })
          }
        })
    },
    deploy(data) {
      if (data === 'site') {
        this.deploySite = !this.deploySite
      }
      if (data === 'device') {
        this.deployDevice = !this.deployDevice
      }
    },
    resolveActiveVariant(data) {
      if (data) return 'success'
      if (!data) return 'danger'
      return 'dark'
    },
    resolveChannelVariant(data) {
      if (data === 'prod') return 'primary'
      if (data === 'beta') return 'info'
      return 'dark'
    },
    resolveScopeVariant(data) {
      if (data === 'superadmin') return 'danger'
      if (data === 'admin') return 'info'
      if (data === 'user') return 'success'
      return 'dark'
    },
  },
}
</script>
